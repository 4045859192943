export const outcomes = [
  "CTR",
  "VCR",
  "Viewability",
  "Brand Lift",
  "Sales",
  "Store Visit",
  "Engagement",
  "Lead",
]

export const dspOptions = [
  "AdForm",
  "AdTheorent",
  "Adobe",
  "Advanced Store",
  "Amazon",
  "Appier",
  "Azira - Formerly Near",
  "Azira LLC",
  "Basis DSP",
  "Beeswax",
  "BidSwitch",
  "BidTheatre AB",
  "Bidease",
  "Bidmachine",
  "Bidtellect",
  "Blis",
  "Bypass",
  "Cadent",
  "Carta DSP",
  "Clearpier",
  "ClueP",
  "Dynamic",
  "Dynalyst",
  "EQ Works",
  "Edge226",
  "Eskimi DSP",
  "GroundTruth",
  "Hawk",
  "IQM",
  "Illumin",
  "InMarket",
  "Infillion",
  "Jamloop",
  "Knorex",
  "Loblaw",
  "LoopMe",
  "Lucead",
  "MadHive",
  "Media Smart",
  "MediaGo",
  "Moloco",
  "NextRoll",
  "NextRoll - PAAPI",
  "Nexxen",
  "Octillion",
  "Opera",
  "Optable",
  "Pelmorex",
  "Pontiac Intelligence",
  "PulsePoint",
  "Quantcast",
  "Remerge",
  "Reset Digital",
  "Roku DSP",
  "Sabio Holdings DSP",
  "Samsung DSP",
  "Simplifi",
  "Sqreem",
  "Stackadapt",
  "Start.io",
  "TEMU",
  "Tangoo SRL",
  "Temu AMS",
  "Temu Video",
  "The Trade Desk",
  "Unity",
  "VDX",
  "Verve DSP",
  "Viant",
  "Vibe.co",
  "Xandr",
  "Yahoo DSP",
  "Zemanta",
  "zMaticoo",
]

export const brandsLogos = [
  {
    name: "Index Exchange",
    logo: require("../../images/brand-logos/index-exchange.svg"),
  },
  {
    name: "Innovid",
    logo: require("../../images/brand-logos/innovid.svg"),
  },
  { name: "Edo", logo: require("../../images/brand-logos/edo.svg") },
  { name: "Cint", logo: require("../../images/brand-logos/cint.svg") },
]

export const brands = [
  { name: "AAA", logo: require("../../images/brand-logos/aaa.svg") },
  { name: "McAfee", logo: require("../../images/brand-logos/mcafee.svg") },
  { name: "TD Bank", logo: require("../../images/brand-logos/td-bank.svg") },
  { name: "Qualcomm", logo: require("../../images/brand-logos/qualcomm.svg") },
  {
    name: "Charles Schwab",
    logo: require("../../images/brand-logos/charles-schwab.svg"),
  },
  {
    name: "Ninjatrader",
    logo: require("../../images/brand-logos/ninjatrader.svg"),
  },
  { name: "Netjets", logo: require("../../images/brand-logos/netjets.svg") },
  { name: "NRG", logo: require("../../images/brand-logos/nrg.svg") },
]

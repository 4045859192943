import { Link } from "gatsby"
import React from "react"
import Logo from "../../images/inpowered-logo-light.inline.svg"

/**
 * @param {{}} param0
 * @returns
 */

const PMPHeader = () => {
  const logoScaleFactor = 150 / 300

  return (
    <div
      className="header-wrapper"
      style={{
        background: "transparent",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <nav
        className="container py-4 px-4"
        style={{
          display: "flex",
          alignItems: "center",
          height: "70px",
          background: "transparent",
        }}
      >
        <Link
          to="/"
          style={{
            display: "block",
            width: 150,
            height: 30,
          }}
        >
          <Logo style={{ height: "100%", width: "100%" }} />
        </Link>
      </nav>
    </div>
  )
}

export default PMPHeader

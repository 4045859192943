import React from "react"
import SEO from "./seo.js"
import HeroSection from "./ai-pmp-components/hero-section.js"
import { brandsLogos } from "./ai-pmp-components/data.js"
import PMPLayout from "./ai-pmp-components/pmp-layout.js"
import PMPHeader from "./ai-pmp-components/pmp-header.js"
import loadable from "@loadable/component"

const PerformanceSection = loadable(() =>
  import("./ai-pmp-components/performance-section.js")
)
const SummarySection = loadable(() =>
  import("./ai-pmp-components/summary-section.js")
)
const CTASection = loadable(() => import("./ai-pmp-components/cta-section.js"))

const AiPMPLandingPage = ({ pathContext: { title } }) => {
  return (
    <PMPLayout>
      <SEO title={title} />
      <PMPHeader />
      <HeroSection brandsLogos={brandsLogos} title={title} />

      <PerformanceSection />
      <SummarySection />
      <CTASection />
    </PMPLayout>
  )
}

export default AiPMPLandingPage

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React from "react"

import Footer from "../footer"
import ReCaptchaProvider from "../re-captcha-provider"

const PMPLayout = ({ children }) => {
  return (
    <ReCaptchaProvider>
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
      </div>

      <Footer showNavigation={false} />
      <noscript>
        <img
          src="https://ws.zoominfo.com/pixel/620e93a614de72001cc2aa77"
          width="1"
          height="1"
          loading="lazy"
          style={{ display: "none" }}
        />
      </noscript>
    </ReCaptchaProvider>
  )
}

PMPLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PMPLayout
